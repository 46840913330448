* {
  margin: 0;
  padding: 0;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eaeaea;
  font-size: 1.22rem;
  @media (min-width: 2000px) {
    font-size: 1.25rem;
  }
}

html {
  /* font-size: 62.5%; */
  font-size: 58.6%;
}

h1 {
  font-size: clamp(7rem, 13vw, 20rem);
}

h2 {
  font-size: clamp(3rem, 4vw, 6rem);
}

h3 {
  font-size: clamp(3.5rem, 3vw, 4rem);
  margin-bottom: 10rem;
  font-weight: 600;
}

h4 {
  font-size: clamp(2.2rem, 1.3vw, 3rem);
  font-weight: 600;
}

h5 {
  font-size: 1.6rem;
  font-weight: 500;
}

p,
li {
  font-size: 1.22rem;
  @media (min-width: 2000px) {
    font-size: 1.25rem;
  }
  margin: 0.2rem 0;
  line-height: 1.75rem;
}

html.lenis,
html.lenis body {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto !important;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-smooth iframe {
  pointer-events: none;
}
